import React, { Fragment } from "react";

export const SPANISH = {
    LANG_CODE: "es",
    COUNTRY_CODE: "es",
    CURRENCY: {
        symbol: '€',
        name: 'EUR',
        id: "0",
        minPaymentAmount: "0.01",
        maxPaymentAmount: "15000",
        billingLabel: "EUR",
        traderKey: "USD/EUR.traders.usd_trader",
    },
    FOOTER: {
        copyright: "Eligma Ltd.",
        version: "versión"
    },
    LOGIN: {
        username: "Nombre de usuario",
        password: "Contraseña",
        usernameEmptyError: "Introducir su nombre de usuario",
        passwordEmptyError: "Introducir la contraseña",
        login: "INICIO DE SESIÓN",
        notAuthorized: "No autorizado",
        hidePassword: "Ocultar",
        showPassword: "Mostrar",
        invalidCredentials: "Nombre de usuario o contraseña incorrecta"
    },
    HEADER: {
        help: "AYUDA",
        callAgent: "Llamar al agente",
        agentNumber: ""
    },
    SIDEBAR: {
        logout: "Cerrar sesión",
        newPayment: "Nuevo pago",
        archive: "Archivo",
        billing: "Facturación",
        faq: "FAQ"
    },
    ARCHIVE: {
        overviewTitle: "Lista de transacciones recibidas",
        refreshLabel: "ACTUALIZAR",
        exportLabel: "EXPORTAR",
        tableHeaders: {
            ID: "ID",
            lastUpdatedTime: "HORA",
            lastUpdatedDate: "FECHA",
            lastUpdated: <span>HORA <span className="mobile-hidden">FECHA</span></span>,
            amount: "VALOR",
            combinedAmount: <span>VALOR <span className="mobile-hidden"> </span></span>,
            cryptoCurrency: "MONEDA",
            cryptoAmount: "VALOR EN CRIPTO",
            print: "IMPRIMIR",
            status: "ESTADO",
            referenceNumber: "REFERENCIA",
            revert: "Revertir"
        },
        export: {
            startDate: "Fecha de inicio",
            endDate: "Fecha de finaliz.",
            error: "Seleccione un intervalo de fechas",
            exportTitle: "Exportación de pagos",
            lastMonth: "El mes pasado",
            showResults: "MOSTRAR RESULTADOS",
            successfulPayments: "Pagos exitosos"
        },
        printLabel: "Imprimir",
        print: {
            amountLabel: "Importe de moneda",
            transactionInfoLabel: "Información de la transacción",
            dateTimeLabel: "Fecha y hora"
        },
        emptyList: "No hay pagos en el archivo"
    },
    PAYMENT: {
        stepLabel: "paso",
        fromLabel: "de",
        createPayment: "Crear pago",
        paymentReady: "Listo para el pago",
        paymentFinished: "Finalizar el pago",
        amount: "Importe de pago",
        amountErrorLow: "La cantidad ingresada es demasiado baja",
        amountErrorHigh: "La cantidad ingresada es demasiado alta",
        referenceNumberLabel: "Número de referencia",
        createPaymentButton: "Continuar con el pago",
        cancelPaymentButton: "cancelar el pago",
        qrCodeReady: "Código QR listo para escanear",
        resetPaymentButton: "Nuevo pago",
        paymentStatus: {
            CRYPTO_PAYMENT_STATUS_OPENED: "ABIERTO",
            CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "EN PAGO",
            CRYPTO_PAYMENT_STATUS_PAID: "EXITOSO",
            CRYPTO_PAYMENT_STATUS_PROCESSING: "PROCESAMIENTO",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "CADUCADO",
            CRYPTO_PAYMENT_STATUS_FAILED: "FALLIDO",
            CRYPTO_PAYMENT_STATUS_NOT_VALID: "NO VÁLIDO",
            CRYPTO_PAYMENT_STATUS_REVERTED: "Reembolso",
            CRYPTO_PAYMENT_STATUS_CANCELED: "CANCELADO",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "MUY BAJO"
        },
        paymentStatusLabel: {
            CRYPTO_PAYMENT_STATUS_OPENED: "Código QR listo para escanear",
            CRYPTO_PAYMENT_STATUS_PAID: "Pago efectuado con éxito",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "El plazo de pago ha expirado",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "Pago muy bajo.\nPara la ayuda, comuníquese con: support@gocrypto.com"
        },
        currencyNotice: "El valor del euro se basa en el tipo de cambio proporcionado por Eligma Ltd. el "
    },
    EXPORT: {
        exportTransactionsLabel: "TRANSACCIONES DE EXPORTACIÓN",
        timeRangeLabel: "Período de tiempo",
        fileTypeLabel: "Tipo de archivo",
        exportLabel: "EXPORTAR",
        lastMonth: "",
        showResults: ""
    },
    REPORT: {
        dailyReport: {
            title: "Facturación",
            exportLabel: "EXPORTAR",
            print: "IMPRIMIR",
            noTransactions: "No hay transacciones",
            currencyLabel: "Valor en",
            totalLabel: "Total en",
            back: "VOLVER",
            amountLabel: "IMPORTE",
            currencyInLabel: "en"
        }
    },
    ERROR: {
        unknownCause: "Esto fue un error inesperado",
        reload: "Intente volver a cargar/borrar su caché primero",
        report: "Si el problema continúa, por favor repórtelo"
    },
    GOCRYPTO: "",
    TOOLTIPS: {
        PAYMENT: {
            mobile: {
                0: "Utilice el teclado para introducir el importe del pago.",
                1: "Presionar Continuar al pago. Esto abre la pantalla para escanear el código QR."
            },
            tablet: {
                0: "Utilice el teclado para introducir el importe del pago.",
                1: "Presionar Continuar al pago. Esto abre la pantalla para escanear el código QR."
            },
            desktop: {
                0: "Utilice el teclado para introducir el importe del pago.",
                1: "Presionar Continuar al pago. Esto abre la pantalla para escanear el código QR."
            }
        },
        ARCHIVE: {
            mobile: {
                0: "Vista de pagos de acuerdo a su tiempo y estado.",
                1: "Exportar pagos de acuerdo a su tiempo y éxito en formato PDF o CSV."
            },
            tablet: {
                0: "Vista de pagos de acuerdo a su tiempo y estado.",
                1: "Exportar pagos de acuerdo a su tiempo y éxito en formato PDF o CSV."
            },
            desktop: {
                0: "Vista de pagos de acuerdo a su tiempo y estado.",
                1: "Exportar pagos de acuerdo a su tiempo y éxito en formato PDF o CSV."
            }
        },
        next: "SIGUIENTE"
    },
    FAQ: {
        title: "webPOS - preguntas frecuentes",
        QA: [
            {
                question: "¿Cómo puedo crear el pago de GoCrypto?",
                answer: <Fragment>
                    <p>Seleccionar "Nuevo pago" en el menú principal e ingrese el monto del pago en el campo de entrada
                    dedicado usando el teclado o la pantalla táctil. Se puede agregar un número de referencia
                        opcionalmente.</p>
                    <p> Haga clic en "Crear pago" y se generará un código QR. Solicite al comprador que escanee este
                    código QR o la pegatina, que se colocó frente a su cajero solo si la pantalla no es fácilmente
                        accesible. </p>
                    <p> Una vez que el comprador complete el proceso, aparecerá la flecha verde con el estado "Pago
                        exitoso". Esto indica que se reciben fondos y puede emitir una factura. </p>
                    <p> Para sus necesidades contables, puede ingresar los “Archivos” e imprimir el recibo de
                        confirmación. </p>
                </Fragment>
            },
            {
                question: "El pago ha caducado. ¿Qué significa esto?",
                answer: <Fragment>
                    <p>Cuando se creó el pago, el código QR apareció junto con un temporizador de cuenta regresiva. A partir de este momento, el usuario debe completar el pago en 5 minutos. Si demoraron más, el proceso expiró y debería crear el pago una vez más.</p>
                </Fragment>
            },
            {
                question: "He introducido un importe de pago incorrecto y ya se ha generado el código QR. ¿Y ahora qué hago?",
                answer: <Fragment>
                    <p>No se preocupe, simplemente haga clic en el botón "Cancelar pago" y repita el proceso una vez
                        más.</p>
                </Fragment>
            },
            {
                question: "¿Dónde puedo revisar el historial de pagos y realizar una exportación?",
                answer: <Fragment>
                    <p>Haga clic en Archivos en el menú principal para desplazarse por el historial completo de pagos.
                    Puede filtrar los resultados mostrados por estado de pago y fecha. Para sus necesidades
                        contables, los archivos se pueden exportar en formato CSV o PDF.</p>
                </Fragment>
            },
            {
                question: "¿Puedo generar el estado de cuenta diario?",
                answer: <Fragment>
                    <p>Por supuesto, haga clic en Facturación en el menú principal donde puede imprimir el extracto
                        diario, donde se fusionan los pagos realizados con las monedas individuales. </p>
                </Fragment>
            },
            {
                question: "¿Qué criptomonedas son compatibles?",
                answer: <Fragment>
                    <p>GoCrypto permite pagos con bitcoin, bitcoin cash, ether, GoC token, litecoin, tezos y Viberate token. Pero no debe preocuparse por esto. Una vez que el usuario escanea el código QR, seleccionará la moneda de pago dentro de su aplicación, por lo que para usted el proceso sigue siendo el mismo en todos los casos. </p>
                    <p>Además de las criptomonedas en algunas regiones, GoCrypto también admite pagos con un valor en euros previamente depositados. </p>
                </Fragment>
            },
            {
                question: "¿Qué carteras pueden utilizar los compradores para completar el pago?",
                answer: <Fragment>
                    <p> Los compradores pueden usar la aplicación Elly o Bitcoin.com. Su proceso es completamente el
                        mismo, sin importar qué cartera elija el usuario.</p>
                </Fragment>
            },
            {
                question: "¿Se admite el pago con moneda regular?",
                answer: <Fragment>
                    <p> Sí, además de las criptomonedas en algunas regiones, GoCrypto también admite pagos con un valor
                        en euros previamente depositados.</p>
                </Fragment>
            },
            {
                question: "Mi comprador está pidiendo un reembolso.",
                answer: <Fragment>
                    <p>Una vez que el pago se haya completado con éxito, los fondos no se pueden reembolsar a través del
                        sistema GoCrypto y el reembolso debe ser realizado por el comerciante.</p>
                </Fragment>
            },
            {
                question: "Tengo problemas para crear un pago o usar GoCrypto POS.",
                answer: <Fragment>
                    <p>Puedes enviar un correo electrónico a <a href="mailto:support@gocrypto.com">support@gocrypto.com </a>.</p>
                </Fragment>
            }
        ]
    },
}
