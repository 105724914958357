import { Fragment } from "react";
import React from "react";

export const CROATIAN = {
    LANG_CODE: "hr",
    COUNTRY_CODE: "hr",
    CURRENCY: {
        symbol: 'HRK',
        name: 'kn',
        id: 11,
        minPaymentAmount: 0.15,
        maxPaymentAmount: 110000.0,
        traderKey: "HRK/EUR.traders.hrk_trader",
        billingLabel: "HRK"
    },
    FOOTER: {
        copyright: "Eligma Ltd.",
        version: "verzija"
    },
    LOGIN: {
        username: "Korisničko ime",
        password: "Lozinka",
        usernameEmptyError: "Unesite korisničko ime",
        passwordEmptyError: "Unesite lozinku",
        login: "PRIJAVA",
        notAuthorized: "Nije autorizirano",
        hidePassword: "Sakrij",
        showPassword: "Prikaži",
        invalidCredentials: "Pogrešno korisničko ime ili lozinka",

    },
    HEADER: {
        help: "POMOĆ",
        callAgent: "Pozovi agenta",
        agentNumber: "+386 51 647 071"
    },
    SIDEBAR: {
        logout: "Odjava",
        newPayment: 'Novo plaćanje',
        archive: <span>Arhiv <span className="mobile-hidden">plaćanja</span></span>,
        billing: "Obračun",
        faq: "FAQ"
    },
    ARCHIVE: {
        overviewTitle: "Arhiv transakcija",
        refreshLabel: "OSVJEŽI",
        exportLabel: "ISPIŠI",
        tableHeaders: {
            ID: "ID",
            lastUpdatedTime: "ČAS",
            lastUpdatedDate: "DATUM",
            lastUpdated: <span>ČAS <span className="mobile-hidden">/ DATUM</span></span>,
            amount: "VRIJEDNOST",
            combinedAmount: "VRIJEDNOST",
            cryptoCurrency: "VRIJEDNOST KRIPTO",
            cryptoAmount: "VRIJEDNOST KRIPTO",
            print: "PRINT",
            status: "STATUS",
            referenceNumber: "REFERENCA",
            revert: "Vrati"
        },
        export: {
            startDate: "Početak",
            endDate: "Kraj",
            error: "Izberite časovni okvir",
            exportTitle: "Ispiši plaćanja",
            lastMonth: "Protekli mesec",
            showResults: "PRIKAŽI REZULTATE",
            successfulPayments: "Uspješna plaćanja"
        },
        printLabel: "Print",
        print: {
            amountLabel: "Iznos u valuti",
            transactionInfoLabel: "Informacije o transakciji",
            dateTimeLabel: "Datum i vrijeme",
        },
        emptyList: "U arhivi nema plaćanja"
    },
    PAYMENT: {
        stepLabel: "korak",
        fromLabel: "od",
        createPayment: "Unesite plaćanje",
        paymentReady: "Spremno plaćanje",
        paymentFinished: "Završeno plaćanje",
        amount: "Iznos plaćanja",
        amountErrorLow: "Uneseni iznos je prenizak",
        amountErrorHigh: "Uneseni iznos je previsok",
        referenceNumberLabel: "Referentni broj",
        createPaymentButton: "Naprijed na plaćanje",
        cancelPaymentButton: "prekini plaćanje",
        qrCodeReady: "Kod pripremljen za skeniranje",
        resetPaymentButton: "Novo plaćanje",
        paymentStatus: {
            CRYPTO_PAYMENT_STATUS_OPENED: "OTVORENO",
            CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "U TIJEKU",
            CRYPTO_PAYMENT_STATUS_PAID: "USPJEŠNO",
            CRYPTO_PAYMENT_STATUS_PROCESSING: "OBRAĐUJEM",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "ISTEKLO",
            CRYPTO_PAYMENT_STATUS_FAILED: "NEUSPJEŠNO",
            CRYPTO_PAYMENT_STATUS_NOT_VALID: "NEVAŽEĆE",
            CRYPTO_PAYMENT_STATUS_REVERTED: "POVRAT",
            CRYPTO_PAYMENT_STATUS_CANCELED: "OTKAZANO",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "PRENISKO"
        },
        paymentStatusLabel: {
            CRYPTO_PAYMENT_STATUS_OPENED: "QR kod je spreman za skeniranje",
            CRYPTO_PAYMENT_STATUS_PAID: "Uspješno plaćanje",
            CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Vrijeme za plaćanje je isteklo",
            CRYPTO_PAYMENT_STATUS_UNDERPAID: "Plaćanje prenisko.\nZa pomoć kontaktirajte GoCrypto na: podrska@gocrypto.com "
        },
        currencyNotice: "Vrijednost eura na temelju srednjeg tečaja Hrvatske narodne banke na dan "
    },
    EXPORT: {
        exportTransactionsLabel: "Pošalji transakcije",
        timeRangeLabel: "Vremenski okvir",
        fileTypeLabel: "Tip datoteke",
        exportLabel: "ISPIŠI",
        lastMonth: "Prazno",
        showResults: "Prazno"
    },

    REPORT: {
        dailyReport: {
            title: "Obračun plaćanja",
            exportLabel: "ISPIŠI",
            print: "ISPIŠI",
            noTransactions: "Obračun je prazan",
            currencyLabel: "Vrijednost u",
            totalLabel: "Ukupno u",
            back: "NATRAG",
            amountLabel: "IZNOS",
            currencyInLabel: "u"

        }
    },
    ERROR: {
        unknownCause: "Dogodila se neočekivana greška",
        reload: "Molimo pokušajte ponovo učitati stranicu / najprije očistiti predmemoriju",
        report: "Ako se problem nastavi, molimo prijavite ga"
    },
    GOCRYPTO: "Prazno",
    TOOLTIPS: {
        PAYMENT: {
            mobile: {
                0: "Pomoću tipkovnice unesite iznos kupnje i kliknite gumb »Naprijed na plaćanje«.",
                1: "Pomoću tipkovnice unesite iznos kupnje i kliknite gumb »Naprijed na plaćanje«."
            },
            tablet: {
                0: "Pomoću tipkovnice unesite iznos kupnje i kliknite gumb »Naprijed na plaćanje«.",
                1: "Pomoću tipkovnice unesite iznos kupnje i kliknite gumb »Naprijed na plaćanje«."
            },
            desktop: {
                0: "Pomoću tipkovnice unesite iznos kupnje i kliknite gumb »Naprijed na plaćanje«.",
                1: "Pomoću tipkovnice unesite iznos kupnje i kliknite gumb »Naprijed na plaćanje«."
            }
        },
        ARCHIVE: {
            mobile: {
                0: "Postavite razdoblje i status plaćanja (uspješno / neuspješno) i pregledajte plaćanja …",
                1: "… ili ispisati PDF i CSV plaćanja"
            },
            tablet: {
                0: "Postavite razdoblje i status plaćanja (uspješno / neuspješno) i pregledajte plaćanja …",
                1: "… ili ispisati PDF i CSV plaćanja"
            },
            desktop: {
                0: "Postavite razdoblje i status plaćanja (uspješno / neuspješno) i pregledajte plaćanja …",
                1: "… ili ispisati PDF i CSV plaćanja"
            }
        },
        next: "NAPRIJED"
    },
    FAQ: {
        title: "webPOS – često postavljana pitanja",
        QA: [
            {
                question: "Kako mogu izvršiti plaćanje putem GoCrypto sistema?",
                answer: <Fragment>
                    <p>Odaberite  “Novo  plaćanje”  u  glavnom  izborniku  i  unesite  iznos  plaćanja  u  polje  za  unos  pomoćutipkovnice ili dodirnog zaslona. Po želji se može dodati referentni broj.</p>
                    <p>Kliknite Naprijed na plaćanje i generirat će se QR kod. Zamolite kupca da skenira ovaj QR kod ili naljepnicu koja se nalazi ispred blagajne (ako blagajnički zaslon nije lako dostupan kupcima).</p>
                    <p>Kada kupac završi postupak, pojavit će se zelena strelica sa statusom Uspješno plaćanje. To znači da su sredstva primljena i da možete izdati račun.</p>
                    <p>U računovodstvene svrhe možete odabrati Arhiva plačanja i ispisati potvrdu.</p>
                </Fragment>
            },
            {
                question: "Što znači obavijest “Vrijeme za plaćanje je isteklo”?",
                answer: <Fragment>
                    <p>Nakon što je nalog za plaćanje kreiran, QR kod pojavljuje se zajedno s timerom i kupac mora izvršiti plaćanje u roku od 5 minuta. Ukoliko plaćanje potraje duže, proces ističe i ponovno se mora kreirati nalog za plaćanje. </p>
                </Fragment>
            },
            {
                question: "Unio sam krivi iznos plaćanja I QR kod je već kreiran. Što učiniti?",
                answer: <Fragment>
                    <p>Bez brige, jednostavno kliknite “Prekini plaćanje” i ponovite proces. </p>
                </Fragment>
            },
            {
                question: "Gdje mogu pregledati popis plaćanja i izvršiti izvoz podataka?",
                answer: <Fragment>
                    <p>Odaberite “Arhiva plaćanja” u glavnom izborniku kako biste prošli kroz cjelokupnu povijest plaćanja. Možete filtrirati rezultate po datumu i statusu plaćanja. U računovodstvene svrhe možete izvršiti izvoz podataka iz Arhive u CSV ili PDF format. </p>
                </Fragment>
            },
            {
                question: "Mogu li generirati dnevni obračun? ",
                answer: <Fragment>
                    <p>Naravno! Odaberite “Obračun” u glavnom izborniku te možete isprintati dnevni izvadak plaćanja u različitim valutama. </p>
                </Fragment>
            },
            {
                question: "Koje kriptovalute su podržane?",
                answer: <Fragment>
                    <p>GoCrypto omogućava plaćanje Bitcoin-om, Bitcoin Cash-om, Ether-om, GoC tokenom, Litecoin-om, Tezos-om in Viberate tokenom te nije potrebno posebno znanje o tim valutama. Kada korisnik skenira QR kod, on će odabrati valutu plaćanja u svojoj aplikaciji; za Vas je proces jednak u svim slučajevima. </p>
                    <p>Osim kriptovaluta, GoCrypto podržava plaćanje s unaprijed uplaćenom vrijednošću u eurima u određenim zemljama. </p>
                </Fragment>
            },
            {
                question: "Koje mobilne novčanike mogu koristiti moji kupci za plaćanje?",
                answer: <Fragment>
                    <p>Vaši kupci mogu koristiti Elly ili Bitcoin.com aplikaciju. Za Vas je proces jednak bez obzira na mobilni novčanik koji koristi Vaš kupac.</p>
                </Fragment>
            },
            {
                question: "Podržava li se plaćanje i drugim službenim valutama (ne-kriptovalutama)?",
                answer: <Fragment>
                    <p>Da, osim kriptovaluta u nekim zemljama je podržan i načinplaćanja s unaprijed uplaćenim iznosom u euro protuvrijednosti. </p>
                </Fragment>
            },
            {
                question: "Kupac traži naknadu.",
                answer: <Fragment>
                    <p>Kad je plaćanje uspješno izvršeno, sredstva se ne mogu nadoknaditi putem GoCrypto sistema; povrat mora izvršiti trgovac.</p>
                </Fragment>
            },
            {
                question: "Imam problema kod stvaranja plaćanja ili korištenja GoCrypto POS.",
                answer: <Fragment>
                    <p>Nazovite našeg agenta na broj +38651647071. Također možete poslati e-mail na <a href="mailto:podrska@gocrypto.com">podrska@gocrypto.com</a>.</p>
                </Fragment>
            }
        ]
    },


};
